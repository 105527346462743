import React from "react";
import { Link } from "react-router-dom";
import io from "socket.io-client";
// import openSocket from "socket.io-client";
import api from "../../../HostEnvironment";
import HostHelper from "../../Helper/hostHelper";
import { apiConstants } from "./../../Constant/constants";
import configuration from "react-global-configuration";

let socket;

let host_details;
let user_details;
let pricing_details;
let page;

class HostChat extends HostHelper {
  state = {
    message: null,
    data: null,
    chatList: null,
    loadingChatList: true,
    socket: false,
    inputMessage: null,
    chatData: null,
    loadingChatData: true,
    headerDetail: null,
    headerDetailsLoadFirst: true,
    headerContentLoading: true,
    loadingStatus: true,
    loadingContent: null,
    loadingStatusChatHistory: true,
    loadingContentChatHistory: null,
    chatListskipCount: 0,
    chatHistorySkipCount: 0,
    loadMoreChatHistory: false,
    chatIndex: 0,
    loading: true,
    chatSidebar: {
      status: true,
      desktop: false
    },
  };

  componentDidMount() {
    // Call api function
    if (this.props.location.state) {
      if (this.props.location.state.page === "singletrip") {
        host_details = this.props.location.state.booking_details;
        user_details = this.props.location.state.booking_details.user_details;
        pricing_details = this.props.location.state.booking_details
          .pricing_details;
        page = "singletrip";
      }

      this.setState({ loading: false });
      this.socketConnectionfun(
        user_details.user_id,
        localStorage.getItem("hostId"),
        host_details.host_id
      );
      this.chatDetailsApiCall();
    } else {
      this.props.history.goBack();
    }
    if (window.innerWidth < 768) {
      this.setState({
        ...this.state,
        loading: false,
        chatSidebar: {
          status: false,
          desktop: false
        }
      });
    } else {
      this.setState({
        ...this.state,
        loading: false,
        chatSidebar: {
          status: true,
          desktop: true
        }
      });
    }

    window.addEventListener('resize', this.handleChatsidebar);
  }

  componentWillUnmount() {
    socket.disconnect();
    window.removeEventListener('resize', this.handleChatsidebar);
  }

  handleChatSideBarToggler = (toggle_status) => {
    this.setState({
      ...this.state, chatSidebar: {
        ...this.state.chatSidebar,
        status: toggle_status,
      }
    });
  }

  socketConnectionfun = (userId, providerId, hostId) => {
    let socketUrl = configuration.get("configData.chat_socket_url");
    socket = io(socketUrl, {
      query:
        `commonid: 'user_id_` +
        userId +
        `_provider_id_` +
        providerId +
        `_host_id_` +
        hostId +
        `', myid: ` +
        providerId,
    });

    socket.emit("update sender", {
      commonid:
        "user_id_" +
        userId +
        "_provider_id_" +
        providerId +
        "_host_id_" +
        hostId,
      myid: providerId,
      providerId: providerId,
    });
    let chatContent;
    socket.on("message", (newData) => {
      //  if(newData.chattype)

      let content = [];
      content.push(newData);
      chatContent = [...this.state.chatData, ...content];
      this.setState({ chatData: chatContent }, () => this.scrollDown());
    });
  };

  chatDetailsApiCall = () => {
    let inputData;

    if (host_details.booking_id === undefined) {
      inputData = {
        host_id: host_details.host_id,
        user_id: user_details.user_id,
        skip: this.state.chatHistorySkipCount,
      };
    } else {
      inputData = {
        booking_id: host_details.booking_id,
        host_id: host_details.host_id,
        user_id: user_details.user_id,
        skip: this.state.chatHistorySkipCount,
      };
    }

    api.postMethod("bookings_chat_details", inputData).then((response) => {
      let chathistory;
      if (response.data.success) {
        if (this.state.loadMoreChatHistory) {
          if (this.state.chatData != null) {
            chathistory = [
              ...response.data.data.chat_messages.reverse(),
              ...this.state.chatData,
            ];
          } else {
            chathistory = [...response.data.data.chat_messages.reverse()];
          }
          this.setState({
            chatData: chathistory,
            loadingChatData: false,
            chatHistorySkipCount:
              response.data.data.chat_messages.length + this.state.chatHistorySkipCount,
            loadingStatusChatHistory: true,
            loadMoreChatHistory: false,
          });
        } else {
          console.log(response.data.data);
          chathistory = response.data.data.chat_messages.reverse();
          this.setState({
            chatData: chathistory,
            loadingChatData: false,
            chatHistorySkipCount: response.data.data.chat_messages.length,
            loadingStatusChatHistory: true,
          }, () => {
            this.scrollDown();
          });
        }
      } else {
        //
      }
    });
  };

  handleOnChange = ({ currentTarget: input }) => {
    this.setState({ inputMessage: input.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    let online_status = 0;
    let inputData = { user_id: user_details.user_id };
    api
      .postMethod("online_status", inputData)
      .then((response) => {
        if (response.data.success) {
          online_status = response.data.data.status;
        }

        let chatData = [
          {
            provider_id: localStorage.getItem("hostId"),
            user_id: user_details.user_id,
            message: this.state.inputMessage,
            host_id: host_details.host_id,
            booking_id: host_details.booking_id,
            chat_type: "pu",
            updated: 'Just now',
            provider_picture: localStorage.getItem("host_picture"),
            notification_type: 1,
            user_status: online_status == 1 ? 1 : 0,
            provider_status: 1,
            sender_id: localStorage.getItem("hostId"),
          },
        ];

        socket.emit("message", chatData[0]);

        let messages;

        if (this.state.chatData != null) {
          messages = [...this.state.chatData, ...chatData];
        } else {
          messages = [...chatData];
        }
        this.setState({
          chatData: messages,
          inputMessage: "",
        }, () => this.scrollDown());
      });
  };

  loadMoreChatHistory = (event) => {
    event.preventDefault();
    this.setState({
      loadingStatusChatHistory: false,
      loadingContentChatHistory: "Loading...",
      loadMoreChatHistory: true,
    });
    this.chatDetailsApiCall();
  };

  scrollDown = () => {

    const objDiv = document.getElementById("messages-holder");

    if (objDiv != null) {
      let differenceNumber =
        objDiv.offsetHeight > objDiv.scrollHeight
          ? objDiv.offsetHeight - objDiv.scrollHeight
          : objDiv.scrollHeight - objDiv.offsetHeight;

      console.log(differenceNumber)

      if (differenceNumber > 100) {
        objDiv.scrollTop = objDiv.scrollHeight;
      } else {
        objDiv.scrollTop = this.state.initialHeight;
        this.setState({
          ...this.state,
          initialHeight: this.state.initialHeight + 20,
        });
      }
    }
  };

  render() {
    const {
      // chatList,
      // loadingChatList,
      chatData,
      loadingChatData,
      // headerDetail,
      // headerContentLoading,
      // loadingStatus,
      // loadingContent,
      loadingContentChatHistory,
      loadingStatusChatHistory,
      loading,
    } = this.state;

    return (
      <div className={`main chatWrapper ${this.state.chatSidebar.desktop && "container mx-auto"}`} id="hostChat">
        {loading ? (
          "Loading..."
        ) : (
          <div className="row m-0 w-100">
            <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 chat-leftsec">
              <div className="chat-details text-center">
                <div className="providerDetailswrapper">

                  <img
                    src={user_details.picture}
                    className="chat-profile-img"
                    alt={user_details.user_name}
                  />
                  <div className="providerDetails">
                    <h4 className="chat-provider-head">{user_details.user_name}</h4>
                  </div>

                </div>

                <div className="text-left hostPriceDetails">
                  <h2 className="chathead">Event details</h2>
                  {host_details.host_name}
                  {host_details.pricing_type == 3 ?
                    host_details.is_custom_pricing == 1 ?
                      <h5 className="choosen-details">
                        <i className="fas fa-address-book mr-3" />
                        Package - Custom Pricing
                      </h5>
                      :
                      <h5 className="choosen-details">
                        <i className="fas fa-address-book mr-3" />
                        Package - {host_details.host_pricing_packages.name} {host_details.host_pricing_packages.base_price_formatted}
                      </h5>
                    : ''}

                  <p className="overview-line my-3" />

                  <div>
                    <h5 className="choosen-details">
                      <i className="fas fa-user mr-3" />
                      {host_details.total_guests} guest
                    </h5>
                    <h5 className="choosen-details">
                      <i className="far fa-calendar-alt mr-3" />
                      {host_details.checkin}
                      {host_details.host_time_slot ? (
                        <>
                          <i className="fas fa-arrow-right ml-3 mr-3" />
                          {host_details.host_time_slot_formatted}
                        </>
                      ) : ''}
                    </h5>
                    <p className="overview-line" />

                    {/* <div className="float-right promo-chat-rgt">
                        <Link target="_blank"
                        to={`/host/add-promo-code`}
                        className="green-btn btn-block btn-sm"

                        >Create Promo Code</Link>

                </div> */}
                  </div>



                  <div className="row">

                    <div className="col-6">
                      {host_details.pricing_type == 1 ? (
                        <h5 className="choosen-details">
                          Base Price
                        </h5>
                      ) : null}
                      {host_details.pricing_type == 2 ? (
                        <h5 className="choosen-details">
                          Guest Price
                        </h5>
                      ) : null}
                      {host_details.pricing_type == 3 ? (
                        <h5 className="choosen-details">
                          Package Price
                        </h5>
                      ) : null}
                      {host_details.pricing_details
                        .total_additional_guest_price == 0 ? (
                        ""
                      ) : (
                        <h5 className="choosen-details">
                          Extra Guest Price
                        </h5>
                      )}
                      {/* <h5 className="choosen-details">
                        Cleaning Fee
                      </h5> */}
                      <h5 className="choosen-details">
                        Services Fee
                      </h5>
                      <h5 className="choosen-details">
                        Tax Price
                      </h5>

                      {/* {host_details.pricing_details
                        .security_deposit == 0 ? (
                        ""
                      ) : (
                        <h5 className="choosen-details">
                          Security Deposit
                        </h5>
                      )} */}
                      {host_details.pricing_details
                        .promo_code_total == 0 ? (
                        ""
                      ) : (
                        <h5 className="choosen-details">
                          Promo code
                        </h5>
                      )}
                    </div>

                    <div className="col-6 text-right">
                      {host_details.pricing_type == 1 ? (
                        <h5 className="choosen-details">
                          {host_details.pricing_details
                            .time_price_formatted}
                        </h5>
                      ) : null}
                      {host_details.pricing_type == 2 ? (
                        <h5 className="choosen-details">
                          {host_details.pricing_details
                            .total_guest_price_formatted}
                        </h5>
                      ) : null}
                      {host_details.pricing_type == 3 ? (
                        <h5 className="choosen-details">
                          {host_details.pricing_details
                            .package_amount_formatted}</h5>
                      ) : null}

                      {host_details.pricing_details
                        .total_additional_guest_price == 0 ? (
                        ""
                      ) : (
                        <h5 className="choosen-details">
                          {host_details.pricing_details
                            .total_additional_guest_price_formatted}</h5>

                      )}
                      {/* <h5 className="choosen-details">
                        {host_details.pricing_details
                          .cleaning_fee_formatted}</h5> */}
                      <h5 className="choosen-details">
                        {host_details.pricing_details
                          .service_fee_formatted}</h5>
                      <h5 className="choosen-details">
                        {host_details.pricing_details
                          .tax_price_formatted}</h5>

                      {/* {host_details.pricing_details
                        .security_deposit == 0 ? (
                        ""
                      ) : (
                        <h5 className="choosen-details">
                        {host_details.pricing_details
                          .security_deposit_formatted}</h5>
                      )} */}
                      {host_details.pricing_details
                        .promo_code_total == 0 ? (
                        ""
                      ) : (
                        <h5 className="choosen-details">
                          - {host_details.pricing_details
                            .promo_code_total_formatted}</h5>
                      )}

                    </div>
                  </div>

                  <p className="overview-line my-3" />

                </div>
                {/* <div className="chatleft-footer">
                  <div className="row">
                    <div className="col-6">
                      <h5 className="choosen-details total text-left">Total </h5>
                    </div>
                    <div className="col-6 text-right">
                      <h5 className="choosen-details total text-right">
                        {host_details.total_formatted}
                      </h5>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 chat-rightsec">
              <div className="relative height-100">
                <div className="chat-header">
                  <div class="chat-list-box single-chat-box">
                    <div className="chatSidebarToggler ml-2" onClick={() => this.props.history.goBack()}>
                      <i class="fas fa-arrow-left"></i>
                    </div>
                    <div class="chat-prof-img">
                      <img
                        src={host_details.host_picture}
                        alt={host_details.host_name}
                      ></img>
                    </div>

                    <div class="chat-prof-content">
                      <h5 class="chat-name">{host_details.host_name}</h5>
                      {/* <p class="chat-date">10 Days Ago</p> */}
                    </div>

                    <div class="clear-both"></div>
                  </div>
                </div>

                <div className="chat-content" id="messages-holder">
                  <div className="text-center">
                    {loadingStatusChatHistory ? "" : loadingContentChatHistory}
                    <a
                      href="category/index.html"
                      className="show-all"
                      onClick={(event) => this.loadMoreChatHistory(event)}
                    >
                      {loadingChatData
                        ? ""
                        : chatData.length > 0
                          ? "Show More"
                          : ""}
                    </a>
                  </div>
                  {loadingChatData
                    ? ""
                    : chatData.map((chat, index) =>
                      <>
                        {chat.confirmed_booking_id || chat.draft_booking_id ?
                          <div className="offer-list-box">
                            <h4>{chat.bookings.host_name}</h4>
                            <p>{chat.message}</p>
                            {chat.confirmed_booking_id ?
                              <ul className="list-unstyled offer-list">
                                <li><i class="fas fa-dollar-sign mr-2"></i> Total: {chat.bookings.total_formatted}</li>
                                <li><i class="far fa-clock mr-2"></i> Booked on: {chat.bookings.created}</li>
                                <li><i class="fas fa-sync-alt mr-2"></i>Event Date: {chat.bookings.checkin}</li>
                              </ul> :
                              <ul className="list-unstyled offer-list">
                                <li><i class="fas fa-dollar-sign mr-2"></i> Total: {chat.bookings.total_formatted}</li>
                                <li><i class="far fa-clock mr-2"></i> Enquired on: {chat.bookings.created}</li>
                                <li><i class="fas fa-sync-alt mr-2"></i>Event Date: {chat.bookings.checkin}</li>
                              </ul>
                            }
                            {chat.confirmed_booking_id ?
                              <Link to={`/host/booking-details/${chat.bookings.booking_id}`} className="view-order-btn">View Bookings</Link>
                              : ''}
                          </div>
                          :
                          chat.chat_type == "up" ? (
                            <div className="chat-left">
                              <div className="display-inline">
                                <img
                                  className="chat-img-left"
                                  src={chat.user_picture}
                                />
                                <div className="chat-content-right">
                                  <div className="chat-message">
                                    {chat.message}{" "}
                                  </div>
                                  <p className="clearfix m-0" />
                                  <p className="chat-time">{chat.updated}</p>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div>
                              <div className="clearfix" />
                              <div className="chat-right">
                                <div className="display-inline">
                                  <div className="chat-content-right">
                                    <div className="chat-message">
                                      {chat.message}{" "}
                                    </div>
                                    <p className="clearfix m-0" />
                                    <div className="chat-status-indication-right">
                                      <p className="chat-time text-right">
                                        {chat.updated}
                                      </p>
                                      {chat.user_status == 1 ?
                                        <p className="read-msg-indication">Read</p>
                                        :
                                        <p className="delivered-msg-indication">Sent</p>
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                      </>
                    )
                  }
                </div>

                <div className="chat-footer">
                  <form onSubmit={this.handleSubmit}>
                    <div className="input-group dropdown">
                      <input
                        type="text"
                        className="form-control dropdown-toggle"
                        data-toggle="dropdown"
                        placeholder="enter your message"
                        name="message"
                        value={this.state.inputMessage}
                        onChange={this.handleOnChange}
                      />
                      <div
                        className="input-group-append"
                        onClick={this.handleSubmit}
                      >
                        <span className="input-group-text" id="basic-addon">
                          <i className="fas fa-paper-plane" />
                        </span>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default HostChat;
